import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Document as DocumentModel} from '../../../api/models/document';
import {BehaviorSubject, combineLatest, firstValueFrom} from 'rxjs';
import {DocumentService} from '../../../services/document/document.service';
import {AnnotationCollections} from 'src/app/api/models/annotation-collections';
import {DocumentQuery} from '../../../queries/document.query';
import {Observable} from 'rxjs/internal/Observable';
import {debounceTime, distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {StampAnnotation} from 'src/app/api/models/stamp-annotation';
import {Vector2D} from '../../../models/vector-2d';
import {SignatureAnnotation} from 'src/app/api/models/signature-annotation';
import {MarkLineAnnotation} from 'src/app/api/models/mark-line-annotation';
import {HighlightAnnotation} from 'src/app/api/models/highlight-annotation';
import {NoteAnnotation} from 'src/app/api/models/note-annotation';
import {AppService} from '../../../services/app/app.service';
import {SelectedItem} from '../../../models/selected-item';
import {DialogService} from '../../../services/dialog/dialog.service';
import {DocumentViewMode} from '../../../models/document-view-mode';
import {Color, hexToRgbColor} from '../../../util/hex-to-rgb-color';
import {AvailableColor, availableColorMap, drawColorTransparency} from '../../../types/available-colors';
import {AppQuery} from '../../../queries/app.query';
import {SinglePageAnnotationData} from 'src/app/api/models/single-page-annotation-data';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {SelectedItemDescription, SelectedItemDescriptionType} from '../../../models/selected-item-description-type';
import {AnnotationQuery} from '../../../queries/annotation.query';
import {Stamp} from 'src/app/api/models/stamp';
import {previewPaddingPercentage} from '../../../constants/ui/document-preview-padding.constant';
import {ListService} from '../../../services/list/list.service';
import {PermissionQuery} from '../../../queries/permission.query';
import {previewFont} from '../../../constants/ui/document-preview-font.constant';
import {previewFontSizePercentage} from '../../../constants/ui/document-preview-font-size.constant';
import {PermissionService} from '../../../services/permission/permission.service';
import {BasicSubscribableComponent} from '../../dummy-components/basic-subscribable-component';
import {ACTION_TYPES} from '../../../constants/action-type.constants';
import {distinctUntilChangedObject} from '../../../util/distinct-until-changed-object';
import {ContactJsEvent} from '../../../models/contact-js-event.model';
import {Pan, PointerListener} from 'contactjs';
import {ColorService} from '../../../services/color/color.service';

const emptyCollection: AnnotationCollections = {
    highlights: [],
    inks: [],
    markLines: [],
    notes: [],
    signatures: [],
    stamps: []
};

@Component({
    selector: 'app-document-view-annotations',
    templateUrl: './document-view-annotations.component.html',
    styleUrls: ['./document-view-annotations.component.scss']
})
export class DocumentViewAnnotationsComponent extends BasicSubscribableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('annotations', { read: ElementRef, static: false }) canvasElement: ElementRef<HTMLCanvasElement> | undefined;
    @ViewChild('boxSelectionToolElement', { read: ElementRef, static: false }) boxSelectionToolElement: ElementRef | undefined;
    @ViewChild('lineSelectionToolElement', { read: ElementRef, static: false }) lineSelectionToolElement: ElementRef | undefined;

    @Input() scale: number;

    activatedSelection: 'box' | 'line' | 'none';
    selectedAnnotation: SelectedItem | undefined;
    imageSize: Vector2D;

    private currentDocument$: BehaviorSubject<DocumentModel | undefined>;
    private currentDocument: DocumentModel | undefined;
    private canvasContext: CanvasRenderingContext2D | null | undefined;
    private page: number;
    private annotations$: Observable<Array<AnnotationCollections>>;
    private currentPageAnnotations$: Observable<AnnotationCollections>;
    private currentPage$: BehaviorSubject<number>;
    private temporaryAnnotations$: Observable<AnnotationCollections | undefined>;
    private zSortedAnnotations$: Observable<Array<Array<SelectedItem>>>;
    private fontSize: number;
    private selectedAnnotation$: Observable<SelectedItemDescription | undefined>;
    private backgroundCanvasContext: CanvasRenderingContext2D | null | undefined;
    private annotationsById$: Observable<{ [key: string]: SelectedItem }>;
    private canvasResolution: Vector2D;
    private canvasSize: Vector2D;
    private boxSelectionTool: HTMLElement | undefined;
    private lineSelectionTool: HTMLElement | undefined;
    private isBusyTransforming: boolean;
    private isBusyDrawing: boolean;
    private documentViewMode$: Observable<DocumentViewMode>;
    private selectedAvailableColor$: Observable<AvailableColor>;
    private devicePixelRatio: number;
    private timeoutDraw: number | undefined;
    private backgroundDrawCanvas: HTMLCanvasElement | undefined;
    private images: { [base64: string]: HTMLImageElement } = {};
    private hasDocumentAnnotations: boolean;
    private drawDebounce: number | undefined;
    private readonly selectionToolPosition: { translate: Vector2D; size: Vector2D };
    private readonly selectionToolMinSize: number;
    private readonly minLineWidthForChecking: number;

    @Input() set document(document: DocumentModel | undefined) {
        this.currentDocument = document;
        this.currentDocument$.next(document);
    }

    @Input() set pageNum(page: number) {
        this.page = page;
        this.currentPage$.next(page);
    }

    constructor(
        private hostElement: ElementRef,
        private appService: AppService,
        private appQuery: AppQuery,
        private documentService: DocumentService,
        private documentQuery: DocumentQuery,
        private dialogService: DialogService,
        private annotationService: AnnotationService,
        private annotationQuery: AnnotationQuery,
        private listService: ListService,
        private permissionQuery: PermissionQuery,
        private permissionService: PermissionService,
        private colorService: ColorService,
    ) {
        super();
        this.hasDocumentAnnotations = false;
        this.currentDocument$ = new BehaviorSubject<DocumentModel | undefined>(undefined);
        this.imageSize = { x: 0, y: 0 };
        this.scale = 1;
        this.devicePixelRatio = 1;
        this.isBusyTransforming = false;
        this.isBusyDrawing = false;
        this.minLineWidthForChecking = 15;
        this.selectedAnnotation$ = this.documentQuery.selectedAnnotation$;
        this.canvasResolution = {
            x: 1,
            y: 1
        };
        this.canvasSize = {
            x: 1,
            y: 1
        };
        this.fontSize = 1;
        this.page = 1;
        this.annotations$ = this.documentQuery.annotations$;
        this.currentPage$ = new BehaviorSubject<number>(this.page);
        this.temporaryAnnotations$ = this.documentQuery.temporaryAnnotations$;
        this.activatedSelection = 'none';
        this.selectionToolMinSize = 16;
        this.selectionToolPosition = {
            translate: {
                x: -1,
                y: -1,
            },
            size: {
                x: this.selectionToolMinSize,
                y: this.selectionToolMinSize
            }
        };
        this.documentViewMode$ = this.documentQuery.documentViewMode$;
        this.selectedAvailableColor$ = this.appQuery.selectedAvailableColor$;
        this.currentPageAnnotations$ =
            combineLatest([this.annotations$, this.currentPage$])
                .pipe(
                    map(([annotationPages, currentPage]: [Array<AnnotationCollections>, number]) => {
                        let newAnnotations: AnnotationCollections = { ...emptyCollection };
                        if (annotationPages) {
                            const annotations = annotationPages[currentPage - 1];
                            if (annotations) {
                                newAnnotations = annotations;
                            }
                        }
                        return newAnnotations;
                    }));
        this.zSortedAnnotations$ = this.temporaryAnnotations$.pipe(
            distinctUntilChanged(),
            map((temporaryAnnotations: AnnotationCollections | undefined) => {
                const annotationCollection: Record<string, any> = (temporaryAnnotations || { ...emptyCollection }) as Record<string, any>;
                const zSorted: Array<Array<SelectedItem>> = [];
                const annotationTypes = Object.keys(annotationCollection);
                for (const annotationType of annotationTypes) {
                    for (const element of annotationCollection[annotationType]) {
                        let zOrder = element.zOrder;
                        if (annotationType === 'stamps' && element.zOrder < 9999) {
                            zOrder += 9999;
                        }
                        if (element !== null) {
                            if (!zSorted[zOrder]) {
                                zSorted[zOrder] = [];
                            }
                            zSorted[zOrder].push({ type: annotationType as SelectedItemDescriptionType, element });
                        }
                    }
                }
                return zSorted;
            }));
        this.annotationsById$ = this.zSortedAnnotations$.pipe(
            distinctUntilChanged(),
            map(arr => {
                const ids: Record<string, any> = {};
                for (const layer of arr) {
                    if (!layer) {
                        continue;
                    }
                    for (const item of layer) {
                        if (item.element.id in ids) {
                            console.error('DUPLICATED ID!');
                        }
                        ids[item.element.id] = item;
                    }
                }
                return ids;
            }));
    }

    ngAfterViewInit(): void {
        (new Promise<void>((resolve, reject) => {
            const checkInterval = setInterval(() => {
                if (this.hostElement && this.hostElement.nativeElement && this.hostElement.nativeElement.offsetWidth > 0 && this.canvasElement && this.canvasElement?.nativeElement &&
                    this.canvasElement?.nativeElement.offsetHeight > 100) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 10);
        })).then(() => {
            if (this.canvasElement) {
                this.lineSelectionTool = this.lineSelectionToolElement?.nativeElement;
                this.boxSelectionTool = this.boxSelectionToolElement?.nativeElement;
                this.onResize();
                this.reset()
                    .then();
                this.addSelectionToolEvents();
            }
        });

    }

    public async ngOnInit(): Promise<void> {
        await this.reset();
        this.backgroundDrawCanvas = document.createElement('canvas');
        const backgroundCanvas = document.createElement('canvas');
        this.backgroundCanvasContext = backgroundCanvas.getContext('2d');
        this.addSubscriptions();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.hasDocumentAnnotations = false;
        this.annotationService.actionMenuOnShowAnnotationMenu = undefined;
        this.annotationService.actionMenuOnDelete = undefined;
        this.resetSelectedItemId();
        this.revertChanges()
            .then();
        this.clearCanvas();
        this.documentService.resetAnnotations();
    }

    public onResize(width?: number, height?: number): void {
        const devicePixelRatio = this.devicePixelRatio = Math.ceil(window.devicePixelRatio);
        const parentElement = this.hostElement.nativeElement.parentElement;
        if (this.canvasElement && this.backgroundCanvasContext && this.backgroundDrawCanvas) {
            const canvas = this.canvasElement.nativeElement;
            const backgroundCanvas = this.backgroundCanvasContext.canvas;
            backgroundCanvas.width = 0;
            backgroundCanvas.height = 0;
            this.backgroundDrawCanvas.width = 0;
            this.backgroundDrawCanvas.height = 0;

            if (!width) {
                width = parentElement.offsetWidth;
            }
            if (!height) {
                height = parentElement.offsetHeight;
            }
            const calculatedWidth = (width || 0) * devicePixelRatio;
            this.backgroundDrawCanvas.width = calculatedWidth;
            const calculatedHeight = (height || 0) * devicePixelRatio;
            this.backgroundDrawCanvas.height = calculatedHeight;

            backgroundCanvas.width = calculatedWidth;
            backgroundCanvas.height = calculatedHeight;
            this.backgroundCanvasContext = backgroundCanvas.getContext('2d');

            canvas.width = calculatedWidth;
            canvas.height = calculatedHeight;
            canvas.style.width = backgroundCanvas.style.width = width + 'px';
            canvas.style.height = backgroundCanvas.style.height = height + 'px';
            this.canvasResolution.x = calculatedWidth;
            this.canvasResolution.y = calculatedHeight;
            this.canvasSize.x = width || 0;
            this.canvasSize.y = height || 0;
            this.fontSize = Math.ceil(previewFontSizePercentage * backgroundCanvas.width);

            this.canvasContext = canvas.getContext('2d');
            const backgroundDrawCanvasContext = this.backgroundDrawCanvas.getContext('2d');
            if (backgroundDrawCanvasContext) {
                backgroundDrawCanvasContext.font = this.fontSize + 'px ' + previewFont;
            }
            if (this.backgroundCanvasContext) {
                this.backgroundCanvasContext.font = this.fontSize + 'px ' + previewFont;
            }
            if (this.canvasContext) {
                this.canvasContext.font = this.fontSize + 'px ' + previewFont;
            }
        }

        const item = this.selectedAnnotation;
        if (item && item.element) {
            this.setSelectionToolToItemById(item.element.id)
                .then(() => {
                    this.needToDraw()
                        .then();
                });
        } else {
            this.needToDraw()
                .then();
        }
    }

    public async setSelectedItemId(itemId: string): Promise<void> {
        const item = await this.getItemById(itemId);
        if (item) {
            const newZIndex = await this.getNextHighZIndex();
            const annotations = JSON.parse(JSON.stringify(await firstValueFrom(this.temporaryAnnotations$.pipe(take(1)))));
            if (annotations) {
                let done = false;
                const annotationTypes = Object.keys(annotations);
                for (const annotationType of annotationTypes) {
                    for (const annotation of (annotations as unknown as Record<string, Array<any>>)[annotationType as any]) {
                        if (annotation.id === itemId) {
                            if (
                                annotationType !== 'stamps' &&
                                (annotationType !== 'signatures' || (annotationType === 'signatures' && itemId.includes('TEMP')))
                            ) {
                                annotation.zOrder = newZIndex;
                            }
                            done = true;
                            break;
                        }
                    }
                    if (done) {
                        break;
                    }
                }
                this.documentService.setTemporaryAnnotations(annotations);
            }
            this.documentService.setSelectedAnnotation({ type: item.type, id: itemId });
        }
    }

    public resetSelectedItemId(): void {
        if (this.selectedAnnotation !== undefined) {
            this.documentService.setSelectedAnnotation(undefined);
        }
        this.needToDraw()
            .then();
    }

    public async getItemIdOnPosition(mousePosition: Vector2D): Promise<string | undefined> {
        const context = this.backgroundCanvasContext;
        if (context) {
            const canvas = context?.canvas;
            const canvasSize: Vector2D = {
                x: canvas.width / this.devicePixelRatio,
                y: canvas.height / this.devicePixelRatio
            };

            const isAddingStamp = this.annotationQuery.getCurrentStampId() !== undefined;
            const zSortedAnnotations = await firstValueFrom(this.zSortedAnnotations$);
            for (let i = zSortedAnnotations.length - 1; i >= 0; --i) {
                const items = zSortedAnnotations[i];
                if (items) {
                    for (const item of items) {
                        // prevents selecting any other annotation type except stamps if the user added a stamp
                        if (item.type !== 'stamps' && isAddingStamp) {
                            continue;
                        }
                        switch (item.type) {
                            case 'stamps':
                            case 'inks':
                            case 'notes':
                            case 'signatures':
                            case 'highlights':
                                const noteItem = item.element as NoteAnnotation;
                                const itemSize: Vector2D = {
                                    x: Math.ceil(noteItem.width * canvasSize.x),
                                    y: Math.ceil(noteItem.height * canvasSize.y)
                                };

                                const itemPos: Vector2D = {
                                    x: Math.ceil(noteItem.startX * canvasSize.x - ((noteItem.width * canvasSize.x) / 2)),
                                    y: Math.ceil(noteItem.startY * canvasSize.y - ((noteItem.height * canvasSize.y) / 2))
                                };
                                if (itemPos.x <= mousePosition.x &&
                                    itemPos.x + itemSize.x >= mousePosition.x &&
                                    itemPos.y <= mousePosition.y &&
                                    itemPos.y + itemSize.y >= mousePosition.y &&
                                    (item.type !== 'stamps' || (item.type === 'stamps' && noteItem.id.includes('TEMP_')))) {
                                    return noteItem.id;
                                }
                                break;
                            case 'markLines':
                                const markLineItem = item.element as MarkLineAnnotation;
                                const p1: Vector2D = {
                                    x: Math.ceil(markLineItem.startX * canvasSize.x),
                                    y: Math.ceil(markLineItem.startY * canvasSize.y)
                                };
                                const p2: Vector2D = {
                                    x: Math.ceil(markLineItem.endX * canvasSize.x),
                                    y: Math.ceil(markLineItem.endY * canvasSize.y)
                                };
                                context.beginPath();
                                context.lineWidth = (markLineItem.lineWidth < this.minLineWidthForChecking) ? this.minLineWidthForChecking : markLineItem.lineWidth;
                                context.moveTo(p1.x, p1.y);
                                context.lineTo(p2.x, p2.y);
                                if (context.isPointInStroke(mousePosition.x, mousePosition.y) || context.isPointInPath(mousePosition.x, mousePosition.y)) {
                                    context.closePath();
                                    return markLineItem.id;
                                }
                                context.closePath();
                                break;
                        }
                    }
                }
            }
        }
        return undefined;
    }

    public async addTemporaryAnnotationToPage(type: SelectedItemDescriptionType, element: any): Promise<any> {
        let diffAnnotations = await firstValueFrom(this.temporaryAnnotations$.pipe(take(1)));
        if (!diffAnnotations) {
            diffAnnotations = { ...emptyCollection };
        }
        const diffAnnotationsCopy: Record<string, Array<any>> = JSON.parse(JSON.stringify(diffAnnotations)) as unknown as Record<string, Array<any>>;
        if (diffAnnotationsCopy) {
            if (!(type in diffAnnotationsCopy)) {
                diffAnnotationsCopy[type] = [];
            }
            element.id = this.generateTemporaryId();
            element.pageNo = this.currentPage$.getValue();
            element.zOrder = type === 'stamps' ? 99999 : await this.getNextHighZIndex();
            diffAnnotationsCopy[type].push(element);
            this.documentService.setTemporaryAnnotations(diffAnnotationsCopy as unknown as AnnotationCollections);
        }
        await this.needToDraw();
        return element;
    }

    public async removeElements(elementIds: Array<string>): Promise<void> {
        const diffAnnotations = await firstValueFrom(this.temporaryAnnotations$);
        if (diffAnnotations) {
            const annotationsById = await firstValueFrom(this.annotationsById$);
            for (const id of elementIds) {
                if (this.selectedAnnotation?.element.id === id) {
                    this.resetSelectedItemId();
                }
                if (id in annotationsById) {
                    const element = annotationsById[id];
                    const elementsByType: Array<any> = diffAnnotations[element.type];
                    diffAnnotations[element.type] = elementsByType.filter(d => d.id !== id);
                }
            }
            this.documentService.setTemporaryAnnotations(diffAnnotations);
            this.needToDraw()
                .then();
        }

        this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
    }

    public setImageSize(imageSize: Vector2D): void {
        this.imageSize.x = imageSize.x;
        this.imageSize.y = imageSize.y;
    }

    @HostListener('window:beforeunload', ['$event'])
    protected onBeforeUnload($event: BeforeUnloadEvent): void {
        if (this.annotationQuery.getHasUnsavedAnnotationChanges()) {
            $event.preventDefault();
        }
    }

    private addSubscriptions(): void {
        this.subscriptions.add(combineLatest([
            this.currentDocument$,
            this.currentPage$
        ])
            .pipe(
                distinctUntilChangedObject()
            )
            .subscribe(async ([document, page]: [DocumentModel | undefined, number]) => {
                this.documentService.resetAnnotations();
                if (!document || !page) {
                    return;
                }
                await this.getDocumentAnnotations();
                await this.needToDraw();
            }));

        this.subscriptions.add(this.selectedAnnotation$.pipe(debounceTime(300))
            .subscribe(async item => {
                if (item) {
                    this.selectedAnnotation = await this.getItemById(item.id);
                    if (item.type !== 'markLines') {
                        this.activatedSelection = 'box';
                    } else {
                        this.activatedSelection = 'line';
                    }
                    this.setSelectionToolToItemById(item.id)
                        .then();
                } else {
                    this.activatedSelection = 'none';
                    this.selectedAnnotation = undefined;
                }
                this.needToDraw()
                    .then();
            }));
        this.subscriptions.add(this.annotationService.actionMenuOnNext.subscribe(async () => {
            if (this.selectedAnnotation && this.selectedAnnotation.type !== 'stamps') {
                const selectedId = this.selectedAnnotation.element.id;
                const nextId = await this.getNextItemIdById(selectedId);
                if (nextId) {
                    this.setSelectedItemId(nextId)
                        .then();
                }
            }
        }));
        this.subscriptions.add(this.annotationService.actionMenuOnClose.subscribe(async () => {
            this.annotationService.setHasUnsavedAnnotationChanges(false);
            const documentViewMode = await firstValueFrom(this.documentViewMode$.pipe(take(1)));
            if (documentViewMode === 'Annotations') {
                const currentStampId = this.annotationQuery.getCurrentStampId();
                if (currentStampId === undefined) {
                    const hasDiff = await this.hasChanges();
                    if (hasDiff) {
                        const saveChanges = await this.dialogService.showChangesConfirmDialog();
                        if (saveChanges) {
                            await this.saveAnnotations();
                            return;
                        }
                    }
                } else {
                    const cancelStamping = await this.dialogService.showConfirmDialog({
                        messageKey: 'STAMP.STAMPING_CANCEL_CONFIRM_MSG',
                        confirmText: 'BUTTON.YES',
                        cancelText: 'BUTTON.NO',
                        appTestTag: 'cancel-stamping',
                    });
                    if (!cancelStamping) {
                        this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
                        return;
                    }
                }
                await this.revertChanges();
                await this.needToDraw();
                this.annotationService.setCurrentStampId(undefined);
                this.appService.removeAllCurrentActionMenus();
                this.appService.setShowingSmallMenuContent(false);
                this.documentService.setDocumentViewMode('Viewing');
            } else if (documentViewMode === 'Drawing' && await this.hasChanges()) {
                this.documentService.setDocumentViewMode('Annotations');
            }
        }));
        this.subscriptions.add(this.annotationService.actionMenuOnNextStampDocument.subscribe(async () => {
            this.reset()
                .then();
        }));
        this.subscriptions.add(this.annotationService.actionMenuOnSave.subscribe(async () => {
            const documentViewMode = await firstValueFrom(this.documentViewMode$.pipe(take(1)));
            if (documentViewMode === 'Annotations') {
                await this.saveAnnotations();
            } else {
                if (documentViewMode === 'Drawing') {
                    const latestZIndexItem: string | undefined = await firstValueFrom(this.temporaryAnnotations$.pipe(
                        map(collection => {
                            if (collection) {
                                let highestZOrder = 0;
                                let id: string | undefined;
                                for (const [annotationType, items] of Object.entries(collection)) {
                                    for (const item of items) {
                                        if (item.id.indexOf('TEMP') !== -1 && item.zOrder > highestZOrder) {
                                            highestZOrder = item.zOrder;
                                            id = item.id;
                                        }
                                    }
                                }

                                return id;
                            }
                            return undefined;
                        }),
                        take(1)
                    ));
                    const type = await firstValueFrom(this.documentQuery.drawAnnotationType$.pipe(take(1)));
                    if (latestZIndexItem && type !== 'signature') {
                        this.setSelectedItemId(latestZIndexItem)
                            .then();
                    }
                }

                this.annotationService.setHasUnsavedAnnotationChanges(false);
            }
        }));
        this.subscriptions.add(this.annotationService.actionMenuOnAddElement.pipe(filter(a => !!a))
            .subscribe(async (item: SelectedItem | undefined) => {
                if (item) {
                    this.documentService.setDocumentViewMode('Annotations');
                    this.annotationService.actionMenuOnAddElement.emit(undefined);
                    const element = item.element;
                    const canvasW = this.canvasElement?.nativeElement.width || 0;
                    const canvasH = this.canvasElement?.nativeElement.height || 0;
                    const context = this.canvasContext;
                    let width = 0;
                    let height = 0;
                    let startX = 0;
                    let startY = 0;
                    if (this.selectedAnnotation) {
                        startX = this.selectedAnnotation.element.startX;
                        startY = this.selectedAnnotation.element.startY;
                        width = ('endX' in this.selectedAnnotation.element)
                                ? Math.abs(this.selectedAnnotation.element.endX - this.selectedAnnotation.element.startX)
                                : this.selectedAnnotation.element.width;
                        height = ('endY' in this.selectedAnnotation.element)
                                 ? Math.abs(this.selectedAnnotation.element.endY - this.selectedAnnotation.element.startY)
                                 : this.selectedAnnotation.element.height;
                    }

                    switch (item.type) {
                        case 'stamps':
                            this.annotationService.setCurrentStampId(item.stampId);
                            const size = this.convertFromPixelPosition({
                                x: element.width,
                                y: element.height
                            }, undefined, this.imageSize);
                            element.width = size.x;
                            element.height = size.y;
                            break;
                        case 'inks':
                            if (element.width > 1 && element.height > 1) {
                                element.width = element.width / (canvasW / 100) / 100;
                                element.height = element.height / (canvasH / 100) / 100;
                            }
                            if (element.width > 0.8 || element.height > 0.8) {
                                if (element.width > 0.8) {
                                    const ratio = element.height / element.width;
                                    element.width = 0.8;
                                    element.height = 0.8 * ratio;
                                }
                                if (element.height > 0.8) {
                                    const ratio = element.width / element.height;
                                    element.height = 0.8;
                                    element.width = 0.8 * ratio;
                                }
                            }
                            if (this.selectedAnnotation) {
                                element.startX = startX - (width / 2) + (element.width / 2);
                                element.startY = startY - (height / 2) + (element.height / 2);
                                this.addPaddingToElement(element);
                            } else {
                                element.startX = 0.5;
                                element.startY = 0.5;
                            }
                            break;
                        case 'notes':
                            const isText = element.backColor !== '';
                            const padding = previewPaddingPercentage * this.canvasResolution.x;
                            let selectedColor = availableColorMap.get(await firstValueFrom(this.selectedAvailableColor$));
                            selectedColor =
                                (selectedColor as string).replace('#', '')
                                    .toUpperCase();
                            if (context) {
                                const rows = this.getTextRows(element.text.split(' '), { x: canvasW * 0.25, y: 0 }, padding, context);
                                context.measureText(element.text.split(' '));
                                element.height = ((this.fontSize + padding) * rows.length) / (canvasW / 100) / 100;
                                const rowSize: Vector2D = {
                                    x: this.getMaxRowWidth(rows, padding, context),
                                    y: 0,
                                };
                                element.width = this.convertFromPixelPosition(rowSize).x;
                                if (element.width < 0.25) {
                                    element.width = 0.25;
                                }
                                if (this.selectedAnnotation) {
                                    element.startX = startX - (width / 2) + (element.width / 2);
                                    element.startY = startY - (height / 2) + (element.height / 2);
                                    if (element.startX < (element.width / 2)) {
                                        element.startX = element.width / 2;
                                    }
                                    if (element.startY < (element.height / 2)) {
                                        element.startY = element.height / 2;
                                    }
                                    this.addPaddingToElement(element);
                                } else {
                                    element.startX = 0.5;
                                    element.startY = 0.5;
                                }
                                element.foreColor = this.colorService.darken('#' + selectedColor, 18)
                                    .replace('#', '') + 'FF';
                                if (!isText) {
                                    element.backColor = selectedColor + drawColorTransparency;
                                }
                            }
                            break;
                    }
                    if (element.width === 0.0 || element.height === 0.0) {
                        return;
                    }
                    await this.addTemporaryAnnotationToPage(item.type, element);
                    this.setSelectedItemId(element.id)
                        .then();
                }

                this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
            }));
        this.annotationService.actionMenuOnShowAnnotationMenu = () => {
            this.appService.setCurrentActionMenuContent(ACTION_TYPES.ANNOTATION);
        };
        this.annotationService.actionMenuOnDelete = async () => {
            if (this.selectedAnnotation) {
                const item = this.selectedAnnotation;
                const itemId = item?.element.id;
                const type = item?.type;
                if (type) {
                    const newTemporaryAnnotations: AnnotationCollections | undefined = await firstValueFrom(this.temporaryAnnotations$.pipe(take(1)));
                    const newArr: Array<any> = [];
                    if (newTemporaryAnnotations) {
                        for (const element of newTemporaryAnnotations[type]) {
                            if (element.id !== itemId) {
                                newArr.push(element);
                            }
                        }

                        const nextId = await this.getNextItemIdById(itemId);
                        newTemporaryAnnotations[type] = newArr;
                        this.documentService.setTemporaryAnnotations(newTemporaryAnnotations);
                        if (this.selectedAnnotation.type !== 'stamps') {
                            if (nextId && nextId !== itemId) {
                                await this.setSelectedItemId(nextId);
                            } else {
                                this.resetSelectedItemId();
                            }
                        } else {
                            this.annotationService.setCurrentStampId(undefined);
                            this.resetSelectedItemId();
                            this.appService.setShowingSmallMenuContent(false);
                            this.appService.setCurrentActionMenuContent(ACTION_TYPES.STAMPS);
                            this.documentService.setDocumentViewMode('Viewing');
                        }
                    }
                }
            }

            this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
        };
        this.annotationService.actionMenuOnCopy = async () => {
            if (this.selectedAnnotation) {
                const element = JSON.parse(JSON.stringify(this.selectedAnnotation.element));
                this.addPaddingToElement(element);
                this.addTemporaryAnnotationToPage(this.selectedAnnotation.type, element)
                    .then(ele => {
                        this.setSelectedItemId(ele.id);
                    });

                this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
            }
        };
    }

    private addPaddingToElement(element: any, paddingFrom: number = 5, paddingTo: number = 5): void {
        const size = this.convertFromPixelPosition(paddingFrom + Math.round(Math.random() * paddingTo), paddingFrom + Math.round(Math.random() * paddingTo));
        if (this.selectedAnnotation) {
            let height = 0;
            const halfWidth = element.width / 2;
            const halfHeight = element.height / 2;
            if (this.selectedAnnotation.type !== 'markLines') {
                height = this.selectedAnnotation.element.height;
            } else {
                let startY = 0;
                let endY = 0;
                element.startX = (this.selectedAnnotation.element.endX < this.selectedAnnotation.element.startX) ? this.selectedAnnotation.element.endX : this.selectedAnnotation.element.startX;
                element.startX += halfWidth;
                if (this.selectedAnnotation.element.endY < this.selectedAnnotation.element.startY) {
                    startY = this.selectedAnnotation.element.endY;
                    endY = this.selectedAnnotation.element.startY;
                } else {
                    startY = this.selectedAnnotation.element.startY;
                    endY = this.selectedAnnotation.element.endY;
                }
                height = endY - startY;
                element.startY = startY + halfHeight;
            }

            if (element.startY + element.height + height + size.y < 1.0) {
                element.startY += height + size.y;
            } else {
                if (element.startY - element.height - size.y >= 0.0) {
                    element.startY -= element.height + size.y;
                }
            }

            if (element.startX < 0.0) {
                element.startX = 0.0;
            }
            if (element.startX + halfWidth > 1.0) {
                element.startX = 1.0 - halfWidth;
            }
            if (element.startY < 0.0) {
                element.startY = 0.0;
            }
            if (element.startY + halfHeight > 1.0) {
                element.startY = 1.0 - halfHeight;
            }
        }
    }

    private async getNextHighZIndex(): Promise<number> {
        const newIndex = await firstValueFrom(this.zSortedAnnotations$.pipe(take(1), map(zArray => {
            let highestIndex = 0;
            for (const [index, itemArray] of zArray.entries()) {
                const i = index as number;
                if (i < 9999) {
                    if (highestIndex < i) {
                        highestIndex = i;
                    }
                }
            }
            return highestIndex + 1;
        })));
        return newIndex > 0 ? newIndex : 1;
    }

    private async reset(): Promise<void> {
        await this.revertChanges();
        this.resetSelectedItemId();
    }

    private async hasChanges(): Promise<boolean> {
        const diff = await this.getChanges();
        return Object.keys(diff).length > 0;
    }

    private clearCanvas(): void {
        const resetCanvas = (canvas: HTMLCanvasElement) => {
            const width = canvas.width;
            const height = canvas.height;
            canvas.width = 0;
            canvas.height = 0;
            window.setTimeout(() => {
                if (canvas) {
                    canvas.remove();
                }
            }, 1000);
            canvas.width = width;
            canvas.height = height;
        };

        if (this.backgroundDrawCanvas) {
            resetCanvas(this.backgroundDrawCanvas);
        }
        if (this.canvasElement?.nativeElement) {
            resetCanvas(this.canvasElement.nativeElement);
        }
        if (this.backgroundCanvasContext) {
            resetCanvas(this.backgroundCanvasContext?.canvas);
        }
    }

    private async revertChanges(): Promise<void> {
        this.annotationService.setCurrentStampId(undefined);
        const annotations = await firstValueFrom(this.currentPageAnnotations$.pipe(take(1)));
        this.documentService.setTemporaryAnnotations(JSON.parse(JSON.stringify(annotations)));
    }

    private convertFromPixelPosition(position: Vector2D | number, y?: number, imageSize?: Vector2D): Vector2D {
        if (typeof (position) === 'number') {
            position = {
                x: position,
                y: y || 0
            };
        }
        const context = this.backgroundCanvasContext;
        if (context) {
            const canvas = context?.canvas;
            let width = canvas.width;
            let height = canvas.height;
            if (imageSize) {
                width = imageSize.x;
                height = imageSize.y;
            }
            position.x = position.x / (width / 100) / 100;
            position.y = position.y / (height / 100) / 100;
        }

        return position as Vector2D;
    }

    private async drawItem(item: SelectedItem, context: CanvasRenderingContext2D): Promise<void> {
        switch (item.type) {
            case 'stamps':
                await this.drawStamp(this.canvasResolution, context, item.element);
                break;
            case 'inks':
                await this.drawInk(this.canvasResolution, context, item.element);
                break;
            case 'signatures':
                await this.drawSignatures(this.canvasResolution, context, item.element);
                break;
            case 'markLines':
                await this.drawMarkLine(this.canvasResolution, context, item.element);
                break;
            case 'highlights':
                await this.drawHighlight(this.canvasResolution, context, item.element);
                break;
            case 'notes':
                await this.drawNote(this.canvasResolution, context, item.element);
                break;
            default:
                console.error('unsupported type', item);
        }
    }

    private async needToDraw(onlyRedrawSelected: boolean = false): Promise<void> {
        if (this.backgroundCanvasContext && this.backgroundDrawCanvas) {
            if (!this.isBusyDrawing && !onlyRedrawSelected) {
                this.isBusyDrawing = true;
                const selectedItem = this.selectedAnnotation;
                const backgroundCanvas = this.backgroundCanvasContext.canvas;
                const sortedElements: Array<Array<SelectedItem>> = await firstValueFrom(this.zSortedAnnotations$);
                const backgroundDrawContext = this.backgroundDrawCanvas.getContext('2d');
                if (backgroundDrawContext) {
                    backgroundDrawContext.clearRect(0, 0, this.backgroundDrawCanvas.width, this.backgroundDrawCanvas.height);
                    for (const items of sortedElements) {
                        if (items) {
                            for (const item of items) {
                                if (selectedItem && selectedItem.element && item.element.id === selectedItem.element.id) {
                                    continue;
                                }
                                await this.drawItem(item, backgroundDrawContext);
                            }
                        }
                    }
                    this.backgroundCanvasContext.clearRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);
                    this.backgroundCanvasContext.drawImage(this.backgroundDrawCanvas, 0, 0);
                }
                if (this.timeoutDraw !== undefined) {
                    this.isBusyDrawing = false;
                }
            }
            if (this.timeoutDraw === undefined) {
                this.timeoutDraw = 10;
                this.draw()
                    .then(() => {
                        this.isBusyDrawing = false;
                    });
            } else {
                this.timeoutDraw = 10;
            }
        }
    }

    private async draw(): Promise<void> {
        const mainContext = this.canvasContext;
        const backgroundCanvasContext = this.backgroundCanvasContext;
        const selectedItem = this.selectedAnnotation;

        if (mainContext && backgroundCanvasContext) {
            const backgroundCanvas = backgroundCanvasContext.canvas;
            const mainCanvas = mainContext.canvas;
            mainContext.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
            mainContext.drawImage(backgroundCanvas, 0, 0);

            if (selectedItem) {
                await this.drawItem(selectedItem, mainContext);
            }
            if (this.timeoutDraw && this.timeoutDraw > 0) {
                this.timeoutDraw--;
                requestAnimationFrame(async () => {
                    await this.draw();
                });
            } else {
                this.timeoutDraw = undefined;
            }
        } else {
            this.timeoutDraw = undefined;
        }

        if (this.drawDebounce !== undefined) {
            window.clearTimeout(this.drawDebounce);
        }
        this.drawDebounce = window.setTimeout(async () => {
            this.annotationService.setHasUnsavedAnnotationChanges(await this.hasChanges());
            this.drawDebounce = undefined;
        }, 100);
    }

    private async setSelectionToolToItemById(itemId: string): Promise<void> {
        const item = await this.getItemById(itemId);
        const context = this.backgroundCanvasContext;
        const drawContext = this.canvasContext;
        if (context && drawContext) {
            let width: number;
            let positionX: number;
            let height: number;
            let positionY: number;

            if (item.type !== 'markLines') {
                const x = item.element.startX;
                const y = item.element.startY;
                const w = item.element.width;
                const h = item.element.height;
                width = this.canvasSize.x * w;
                positionX = (this.canvasSize.x * x) - (width / 2);
                height = this.canvasSize.y * h;
                positionY = (this.canvasSize.y * y) - (height / 2);
            } else {
                const lineItem: MarkLineAnnotation = item.element;
                positionX = lineItem.startX * this.canvasSize.x;
                positionY = lineItem.startY * this.canvasSize.y;
                width = lineItem.endX * this.canvasSize.x;
                height = lineItem.endY * this.canvasSize.y;
            }

            this.selectionToolPosition.translate.x = Math.round(positionX);
            this.selectionToolPosition.translate.y = Math.round(positionY);
            this.selectionToolPosition.size.x = Math.round(width);
            this.selectionToolPosition.size.y = Math.round(height);
            this.requestSelectionToolElementUpdate();
        }
    }

    private adjustLine(x1: number, y1: number, x2: number, y2: number, line: HTMLElement): void {
        const cathete = (y2 - y1);
        const counterCathete = (x2 - x1);
        const distance = Math.sqrt(cathete * cathete + counterCathete * counterCathete);
        let angleInDegree = Math.atan2(cathete, counterCathete) * 180 / Math.PI;
        angleInDegree -= 90;
        line.style.transform = 'translate(-12px, 0px) rotate(' + angleInDegree + 'deg)';
        line.style.height = distance + 'px';
        line.style.top = y1 + 'px';
        line.style.left = x1 + 'px';
    }

    private requestSelectionToolElementUpdate(): void {
        if (!this.isBusyTransforming) {
            this.isBusyTransforming = true;
            requestAnimationFrame(() => {
                if (this.activatedSelection === 'box' && this.boxSelectionTool) {
                    this.boxSelectionTool.style.left = (this.selectionToolPosition.translate.x) + 'px';
                    this.boxSelectionTool.style.top = (this.selectionToolPosition.translate.y) + 'px';
                    this.boxSelectionTool.style.width = (this.selectionToolPosition.size.x) + 'px';
                    this.boxSelectionTool.style.height = (this.selectionToolPosition.size.y) + 'px';
                }
                if (this.activatedSelection === 'line' && this.lineSelectionTool) {
                    this.adjustLine(
                        this.selectionToolPosition.translate.x,
                        this.selectionToolPosition.translate.y,
                        this.selectionToolPosition.size.x,
                        this.selectionToolPosition.size.y,
                        this.lineSelectionTool);
                }
                this.isBusyTransforming = false;
            });
        }
    }

    private async drawNote(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: NoteAnnotation): Promise<void> {
        const size: Vector2D = {
            x: Math.ceil(item.width * canvasSize.x),
            y: Math.ceil(item.height * canvasSize.y)
        };

        const position: Vector2D = {
            x: Math.ceil(item.startX * canvasSize.x - ((item.width * canvasSize.x) / 2)),
            y: Math.ceil(item.startY * canvasSize.y - ((item.height * canvasSize.y) / 2))
        };

        const backgroundColor: Color | undefined = hexToRgbColor('#' + item.backColor);
        if (backgroundColor && backgroundColor.a !== 0) {
            context.fillStyle = 'rgba(' + backgroundColor.r + ',' + backgroundColor.g + ',' + backgroundColor.b + ',' + backgroundColor.a + ')';
            context.fillRect(position.x, position.y, size.x, size.y);
        }
        const textColor: Color | undefined = hexToRgbColor('#' + item.foreColor);
        if (item.text && textColor && textColor.a !== 0) {
            context.fillStyle = 'rgba(' + textColor.r + ',' + textColor.g + ',' + textColor.b + ',' + textColor.a + ')';
            this.drawText(item.text, position, size, previewPaddingPercentage * this.canvasResolution.x, context);
        }
    }

    private drawText(text: string, position: Vector2D, size: Vector2D, padding: number, context: CanvasRenderingContext2D): void {
        const textParts = text.split(' ');
        const rows = this.getTextRows(textParts, size, padding, context);
        for (let i = 0, l = rows.length; i < l; ++i) {
            context.fillText(rows[i].join(' '), position.x + padding, position.y + ((this.fontSize + padding) * (i + 1)));
        }
    }

    private getMaxRowWidth(rows: Array<Array<string>>, padding: number, context: CanvasRenderingContext2D): number {
        let largestWidth = 0;
        for (let i = 0, l = rows.length; i < l; ++i) {
            const measurement: TextMetrics = context.measureText(rows[i].join(' '));
            if (measurement.width > largestWidth) {
                largestWidth = measurement.width;
            }
        }
        return largestWidth + (padding * 4);
    }

    private getTextRows(textParts: Array<string>, size: Vector2D, padding: number, context: CanvasRenderingContext2D, row = 0, rows: Array<Array<string>> = []): Array<Array<string>> {
        const maxWidth: number = size.x - (padding * 2);
        let rowText: Array<string> = [];
        for (let i = 0, l = textParts.length; i < l; ++i) {
            const word = textParts.shift() as string;
            const measurement: TextMetrics = context.measureText([...rowText, word].join(' '));
            if (measurement.width < maxWidth) {
                rowText.push(word);
            } else {
                rows[row] = [...rowText];
                rowText = [word];
                ++row;
            }
        }
        if (rowText.length > 0) {
            rows[row] = [...rowText];
        }
        return rows.filter(textArray => textArray.length > 0);
    }

    private async drawHighlight(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: HighlightAnnotation): Promise<void> {
        const size: Vector2D = {
            x: Math.ceil(item.width * canvasSize.x),
            y: Math.ceil(item.height * canvasSize.y)
        };

        const position: Vector2D = {
            x: Math.ceil(item.startX * canvasSize.x - ((item.width * canvasSize.x) / 2)),
            y: Math.ceil(item.startY * canvasSize.y - ((item.height * canvasSize.y) / 2))
        };

        const color: Color | undefined = hexToRgbColor('#' + item.color);
        if (color && color.a !== 0) {
            context.fillStyle = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
            context.fillRect(position.x, position.y, size.x, size.y);
        }
    }

    private async drawMarkLine(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: MarkLineAnnotation): Promise<void> {
        const point1: Vector2D = {
            x: Math.ceil(item.startX * canvasSize.x),
            y: Math.ceil(item.startY * canvasSize.y)
        };
        const point2: Vector2D = {
            x: Math.ceil(item.endX * canvasSize.x),
            y: Math.ceil(item.endY * canvasSize.y)
        };
        context.lineWidth = Math.floor(item.lineWidth / 2);
        const color: Color | undefined = hexToRgbColor('#' + item.color);
        if (color && color.a !== 0) {
            context.strokeStyle = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
            context.beginPath();
            context.moveTo(point1.x, point1.y);
            context.lineTo(point2.x, point2.y);
            context.stroke();
            context.closePath();
        }
    }

    private drawInk(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: StampAnnotation): Promise<void> {
        return this.drawCenteredImage(canvasSize, context, item.image, item.startX, item.startY, item.width, item.height);
    }

    private drawStamp(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: StampAnnotation): Promise<void> {
        return this.drawCenteredImage(canvasSize, context, item.image, item.startX, item.startY, item.width, item.height);
    }

    private drawSignatures(canvasSize: Vector2D, context: CanvasRenderingContext2D, item: SignatureAnnotation): Promise<void> {
        return this.drawCenteredImage(canvasSize, context, item.image, item.startX, item.startY, item.width, item.height);
    }

    private drawCenteredImage(canvasSize: Vector2D, context: CanvasRenderingContext2D, imageSrc: any, x: number, y: number, w: number, h: number): Promise<void> {
        function drawImage(image: HTMLImageElement) {
            const width = canvasSize.x * w;
            const positionX = (canvasSize.x * x) - (width / 2);
            const height = canvasSize.y * h;
            const positionY = (canvasSize.y * y) - (height / 2);
            context.drawImage(image, Math.round(positionX), Math.round(positionY), Math.round(width), Math.round(height));
        }

        return new Promise<void>((resolve, reject) => {
            if (!this.images[imageSrc]) {

                const image = this.images[imageSrc] = new Image();
                this.images[imageSrc].addEventListener('load', () => {
                    drawImage(image);
                    resolve();
                });
                this.images[imageSrc].addEventListener('error', () => {
                    reject();
                });
                this.images[imageSrc].src = 'data:image/png;base64,' + imageSrc;
            } else {
                drawImage(this.images[imageSrc]);
                resolve();
            }
        });
    }

    private async getChanges(): Promise<Record<string, boolean>> {
        const differences: { [key: string]: boolean } = {};
        const temporaryAnnotations: { [key: string]: any } = await firstValueFrom(this.temporaryAnnotations$) || { ...emptyCollection };
        const currentAnnotations: { [key: string]: any } = await firstValueFrom(this.currentPageAnnotations$);
        const annotationTypes = Object.keys(currentAnnotations);
        for (const annotationType of annotationTypes) {
            const currentIds: Array<string> = currentAnnotations[annotationType].map((x: { id: string }) => x.id);
            const temporaryIds: Array<string> = temporaryAnnotations[annotationType].map((x: { id: string }) => x.id);
            const idsFound: Array<string> = [];
            const differenceToTemporary = currentAnnotations[annotationType].filter((x: { id: string }) => {
                if (!temporaryIds.includes(x.id)) {
                    return true;
                }
                if (!idsFound.includes(x.id)) {
                    idsFound.push(x.id);
                }
                return false;
            });
            const differenceToCurrent = temporaryAnnotations[annotationType].filter((x: { id: string }) => {
                if (!currentIds.includes(x.id)) {
                    return true;
                }
                if (!idsFound.includes(x.id)) {
                    idsFound.push(x.id);
                }
                return false;
            });
            for (const id of idsFound) {
                const currentItems = currentAnnotations[annotationType].filter((x: any) => x.id === id);
                const diffItems = temporaryAnnotations[annotationType].filter((x: any) => x.id === id);
                if (currentItems.length + diffItems.length > 0) {
                    if (currentItems.length > 0 || diffItems.length > 0) {
                        const keys = Object.keys(currentItems[0]);
                        let differenceFound = false;
                        for (const key of keys) {
                            if (typeof (currentItems[0][key]) === 'number') {
                                //Round numbers, float is rounded incorrect and a non-existing difference is detected
                                const firstNumber = Math.round(currentItems[0][key] * 10000);
                                const secondNumber = Math.round(diffItems[0][key] * 10000);
                                if (firstNumber !== secondNumber) {
                                    differenceFound = true;
                                    break;
                                }
                            } else {
                                if (currentItems[0][key] !== diffItems[0][key]) {
                                    differenceFound = true;
                                    break;
                                }
                            }
                        }
                        if (differenceFound) {
                            differences[annotationType] = true;
                            break;
                        }
                    } else {
                        differences[annotationType] = true;
                        break;
                    }
                }
            }
            if (differenceToCurrent.length > 0 || differenceToTemporary.length > 0) {
                differences[annotationType] = true;
            }
        }
        return differences;
    }

    private async getDocumentAnnotations(): Promise<void> {
        let getDocumentAnnotationsPromise;
        if (!this.hasDocumentAnnotations) {
            this.hasDocumentAnnotations = true;
            getDocumentAnnotationsPromise = new Promise<void>(async (resolve) => {
                if (!!this.currentDocument) {
                    const documentId = this.currentDocument?.id as string;
                    await this.permissionService.fetchDocumentPermission(documentId, true);
                    if (this.permissionQuery.hasDocumentPermission(documentId, 'DocumentsGetAnnotations')) {
                        await this.documentService.fetchDocumentAnnotations(documentId, this.page);
                        const pageAnnotations = this.documentQuery.getAnnotationsFromPage(this.page);
                        this.documentService.setTemporaryAnnotations(pageAnnotations);
                    }
                }
                this.hasDocumentAnnotations = false;
                resolve();
            });
        }
        return getDocumentAnnotationsPromise;
    }

    private async getItemById(itemId: string): Promise<SelectedItem> {
        const item = await firstValueFrom(this.annotationsById$.pipe(take(1), map((data: { [key: string]: any }) => data[itemId])));
        return { ...item };
    }

    private updateSelectedItem(selectedItem: any, x1: number, y1: number, x2: number, y2: number): void {
        if (this.activatedSelection === 'box' && this.boxSelectionTool) {
            selectedItem.startY = y1;
            selectedItem.startX = x1;
            selectedItem.width = x2;
            selectedItem.height = y2;
        }
        if (this.activatedSelection === 'line' && this.lineSelectionTool) {
            const markLine = (selectedItem as MarkLineAnnotation);
            markLine.startX = x1;
            markLine.startY = y1;
            markLine.endX = x2;
            markLine.endY = y2;
        }
        this.needToDraw(true)
            .then();
    }

    private addSelectionToolEvents(): void {
        if (this.lineSelectionTool) {
            this.addLineSelectionToolEvents();
        }

        if (this.boxSelectionTool) {
            this.addBoxSelectionToolEvents();
        }
    }

    private addBoxSelectionToolEvents(): void {
        if (!this.boxSelectionTool) {
            return;
        }

        let selectedItem: SelectedItem | undefined;
        const minSize = this.selectionToolMinSize;

        const pointerListener = new PointerListener(this.boxSelectionTool, {
            'supportedGestures': [Pan]
        });
        const previousSize: Vector2D = { x: 0, y: 0 };
        const previousTranslate: Vector2D = { x: 0, y: 0 };
        let isResizing = false;
        let panTarget: Element | undefined;
        const onPan = (e: ContactJsEvent): void => {
            const documentWidth = this.hostElement.nativeElement?.offsetWidth || 0;
            const documentHeight = this.hostElement.nativeElement?.offsetHeight || 0;
            if (e.type === 'panend') {
                panTarget = undefined;
                selectedItem = undefined;
            }
            if (e.type === 'panstart' && panTarget === undefined) {
                const target = e.target as Element;
                if (target && (target.classList.contains('selection-tool') || target.classList.contains('border-circle'))) {
                    panTarget = target;
                    selectedItem = this.selectedAnnotation;
                    previousSize.x = this.selectionToolPosition.size.x;
                    previousSize.y = this.selectionToolPosition.size.y;
                    previousTranslate.x = this.selectionToolPosition.translate.x;
                    previousTranslate.y = this.selectionToolPosition.translate.y;

                    isResizing = (panTarget.classList.contains('border-circle'));
                }
            }
            if (e.type === 'pan') {
                if (!selectedItem || (selectedItem && selectedItem.type === 'signatures' && !selectedItem.element.id.includes('TEMP_')) ||
                    (selectedItem && selectedItem.type === 'stamps' && !selectedItem.element.id.includes('TEMP_'))) {
                    return;
                }
                let deltaX = e.detail.global.deltaX / (this.scale || 1);
                let deltaY = e.detail.global.deltaY / (this.scale || 1);
                let x = previousTranslate.x;
                if (!isResizing) {
                    x += deltaX;
                    if (x < 0) {
                        x = 0;
                    }
                    if (x + previousSize.x > documentWidth) {
                        x = documentWidth - previousSize.x;
                    }
                    let y = previousTranslate.y + deltaY;
                    if (y < 0) {
                        y = 0;
                    }
                    if (y + previousSize.y > documentHeight) {
                        y = documentHeight - previousSize.y;
                    }
                    this.selectionToolPosition.translate.x = x;
                    this.selectionToolPosition.translate.y = y;
                } else {
                    let sizeX: number;
                    let sizeY: number;
                    const ratioX = previousSize.x / previousSize.y;
                    const ratioY = previousSize.y / previousSize.x;
                    if (selectedItem.type === 'inks' || selectedItem.type === 'signatures' || selectedItem.type === 'stamps') {
                        if (deltaX < deltaY) {
                            if (deltaX > 0) {
                                deltaY = deltaX * ratioY;
                            } else {
                                if (Math.abs(deltaX) < deltaY) {
                                    deltaY = deltaX * ratioY;
                                } else {
                                    deltaX = deltaY * ratioX;
                                }
                            }
                        } else {
                            if (deltaY > 0) {
                                deltaX = deltaY * ratioX;
                            } else {
                                if (Math.abs(deltaY) < deltaX) {
                                    deltaX = deltaY * ratioX;
                                } else {
                                    deltaY = deltaX * ratioY;
                                }
                            }
                        }
                        sizeX = previousSize.x + deltaX;
                        sizeY = previousSize.y + deltaY;
                        if (sizeX < minSize) {
                            deltaX = minSize - previousSize.x;
                            deltaY = deltaX * ratioY;
                        }
                        if (sizeY < minSize) {
                            deltaY = minSize - previousSize.y;
                            deltaX = deltaY * ratioX;
                        }
                        if (previousTranslate.x + sizeX > documentWidth) {
                            deltaX = documentWidth - previousTranslate.x - previousSize.x;
                            deltaY = deltaX * ratioY;
                        }
                        if (previousTranslate.y + sizeY > documentHeight) {
                            deltaY = documentHeight - previousTranslate.y - previousSize.y;
                            deltaX = deltaY * ratioX;
                            if (previousTranslate.x + deltaX + previousSize.x > documentWidth) {
                                deltaX = documentWidth - previousTranslate.x - previousSize.x;
                                deltaY = deltaX * ratioY;
                            }
                        }
                        sizeX = previousSize.x + deltaX;
                        sizeY = previousSize.y + deltaY;
                    } else {
                        sizeX = previousSize.x + deltaX;
                        sizeY = previousSize.y + deltaY;

                        if (sizeX < minSize) {
                            sizeX = minSize;
                        }
                        if (previousTranslate.x + sizeX > documentWidth) {
                            sizeX = documentWidth - previousTranslate.x;
                        }
                        if (sizeY < minSize) {
                            sizeY = minSize;
                        }
                        if (previousTranslate.y + sizeY > documentHeight) {
                            sizeY = documentHeight - previousTranslate.y;
                        }

                        if (selectedItem.type === 'notes') {
                            const canvasContext = this.canvasContext;
                            const text = (selectedItem.element as NoteAnnotation).text;
                            const words = text.split(' ');
                            let measurement;
                            let measurementWidth;
                            let largestWordWidth = 0;
                            if (canvasContext) {
                                for (const word of words) {
                                    measurement = canvasContext.measureText(word);
                                    measurementWidth = measurement.width / this.devicePixelRatio;
                                    if (measurementWidth > largestWordWidth) {
                                        largestWordWidth = measurementWidth;
                                    }
                                }
                            }
                            const padding = previewPaddingPercentage * this.canvasResolution.x * 2;
                            if (largestWordWidth > 0 && sizeX < largestWordWidth + padding) {
                                sizeX = largestWordWidth + padding;
                            }
                            const rows = this.getTextRows(words, { x: sizeX, y: sizeY }, padding, canvasContext as CanvasRenderingContext2D);
                            const numberOfRows = rows.length;
                            const textHeight = numberOfRows * (this.fontSize + padding) / this.devicePixelRatio;

                            if (sizeY < textHeight) {
                                sizeY = textHeight;
                            }
                        }
                    }
                    this.selectionToolPosition.size.x = sizeX;
                    this.selectionToolPosition.size.y = sizeY;
                }
            }
            if (selectedItem) {
                const xPercent1 = ((this.selectionToolPosition.translate.x + (this.selectionToolPosition.size.x / 2)) / (documentWidth / 100)) / 100;
                const xPercent2 = (this.selectionToolPosition.size.x / (documentWidth / 100)) / 100;
                const yPercent1 = ((this.selectionToolPosition.translate.y + (this.selectionToolPosition.size.y / 2)) / (documentHeight / 100)) / 100;
                const yPercent2 = (this.selectionToolPosition.size.y / (documentHeight / 100)) / 100;
                if (selectedItem.type !== 'signatures' || (selectedItem.type === 'signatures' && selectedItem.element.id.includes('TEMP_'))) {
                    this.updateSelectedItem(selectedItem.element, xPercent1, yPercent1, xPercent2, yPercent2);
                }
            }
            this.requestSelectionToolElementUpdate();
        };
        pointerListener.on('panstart pan panend', (event: Event) => {
            onPan(event as ContactJsEvent);
        });
    }

    private addLineSelectionToolEvents(): void {
        if (!this.lineSelectionTool) {
            return;
        }

        let selectedItem: SelectedItem | undefined;
        const pointerListener = new PointerListener(this.lineSelectionTool, {
            'supportedGestures': [Pan]
        });


        const previousSize: Vector2D = { x: 0, y: 0 };
        const previousTranslate: Vector2D = { x: 0, y: 0 };
        let isFirstBorderCircle = false;
        let isMiddleBorderCircle = false;
        let isSecondBorderCircle = false;
        let panTarget: Element | undefined;
        const onPan = (e: ContactJsEvent): void => {
            const documentWidth: number = this.hostElement.nativeElement?.offsetWidth || 0;
            const documentHeight: number = this.hostElement.nativeElement?.offsetHeight || 0;
            if (e.type === 'panend') {
                panTarget = undefined;
                selectedItem = undefined;
            }
            if (e.type === 'panstart' && panTarget === undefined) {
                const target = e.target as Element;
                if (target && (target.classList.contains('line-selection-tool') || target.classList.contains('border-circle'))) {
                    panTarget = target;
                    selectedItem = this.selectedAnnotation;
                    previousSize.x = this.selectionToolPosition.size.x;
                    previousSize.y = this.selectionToolPosition.size.y;
                    previousTranslate.x = this.selectionToolPosition.translate.x;
                    previousTranslate.y = this.selectionToolPosition.translate.y;
                    isFirstBorderCircle = false;
                    isSecondBorderCircle = false;
                    isMiddleBorderCircle = false;

                    if (panTarget.classList.contains('border-circle-middle')) {
                        isMiddleBorderCircle = true;
                    }
                    if (panTarget.classList.contains('border-circle-1')) {
                        isFirstBorderCircle = true;
                    }
                    if (panTarget.classList.contains('border-circle-2')) {
                        isSecondBorderCircle = true;
                    }
                }
            }

            if (e.type === 'pan') {
                const deltaX = e.detail.global.deltaX / (this.scale || 1);
                const deltaY = e.detail.global.deltaY / (this.scale || 1);
                let x1 = previousTranslate.x;
                let y1 = previousTranslate.y;
                let x2 = previousSize.x;
                let y2 = previousSize.y;
                if (isMiddleBorderCircle) {
                    x1 += deltaX;
                    y1 += deltaY;
                    x2 += deltaX;
                    y2 += deltaY;

                    if (previousSize.x < previousTranslate.x) {
                        if (x2 <= 0) {
                            x2 = 0;
                            x1 = Math.abs(previousTranslate.x - previousSize.x);
                        }
                        if (x1 >= documentWidth) {
                            x1 = documentWidth;
                            x2 = documentWidth - Math.abs(previousTranslate.x - previousSize.x);
                        }
                    } else {
                        if (x1 <= 0) {
                            x1 = 0;
                            x2 = Math.abs(previousTranslate.x - previousSize.x);
                        }
                        if (x2 >= documentWidth) {
                            x2 = documentWidth;
                            x1 = documentWidth - Math.abs(previousSize.x - previousTranslate.x);
                        }
                    }

                    if (previousSize.y < previousTranslate.y) {
                        if (y2 <= 0) {
                            y2 = 0;
                            y1 = Math.abs(previousTranslate.y - previousSize.y);
                        }
                        if (y1 >= documentHeight) {
                            y1 = documentHeight;
                            y2 = documentHeight - Math.abs(previousTranslate.y - previousSize.y);
                        }
                    } else {
                        if (y1 <= 0) {
                            y1 = 0;
                            y2 = Math.abs(previousTranslate.y - previousSize.y);
                        }
                        if (y2 >= documentHeight) {
                            y2 = documentHeight;
                            y1 = documentHeight - Math.abs(previousSize.y - previousTranslate.y);
                        }
                    }
                } else {
                    if (isFirstBorderCircle) {
                        x1 += deltaX;
                        y1 += deltaY;
                    } else {
                        if (isSecondBorderCircle) {
                            x2 += deltaX;
                            y2 += deltaY;
                        }
                    }
                    if (x1 < 0) {
                        x1 = 0;
                    }
                    if (x2 < 0) {
                        x2 = 0;
                    }
                    if (x1 > documentWidth) {
                        x1 = documentWidth;
                    }
                    if (x2 > documentWidth) {
                        x2 = documentWidth;
                    }

                    if (y1 < 0) {
                        y1 = 0;
                    }
                    if (y2 < 0) {
                        y2 = 0;
                    }
                    if (y1 > documentHeight) {
                        y1 = documentHeight;
                    }
                    if (y2 > documentHeight) {
                        y2 = documentHeight;
                    }
                }
                this.selectionToolPosition.translate.x = x1;
                this.selectionToolPosition.translate.y = y1;
                this.selectionToolPosition.size.x = x2;
                this.selectionToolPosition.size.y = y2;
            }
            if (selectedItem) {
                const xPercent1 = (this.selectionToolPosition.translate.x / (documentWidth / 100)) / 100;
                const xPercent2 = (this.selectionToolPosition.size.x / (documentWidth / 100)) / 100;
                const yPercent1 = (this.selectionToolPosition.translate.y / (documentHeight / 100)) / 100;
                const yPercent2 = (this.selectionToolPosition.size.y / (documentHeight / 100)) / 100;
                this.updateSelectedItem(selectedItem.element, xPercent1, yPercent1, xPercent2, yPercent2);
            }
            this.requestSelectionToolElementUpdate();

        };

        pointerListener.on('panstart pan panend', (event: Event) => {
            onPan(event as ContactJsEvent);
        });
    }

    private async getNextItemIdById(id: string): Promise<string | undefined> {
        const items = await firstValueFrom(this.annotationsById$);
        const ids = Object.keys(items);
        const len = ids.length;
        let index = ids.indexOf(id);
        if (index !== -1) {
            if (index + 1 < len) {
                index++;
            } else {
                index = 0;
            }
            return ids[index];
        }
        return undefined;
    }

    private generateTemporaryId(): string {
        return 'TEMP_' + (new Date()).getTime();
    }

    private async saveAnnotations(): Promise<void> {
        this.appService.showSpinner();
        const data = await firstValueFrom(this.temporaryAnnotations$.pipe(take(1)));

        const clearAnnotation = (annotation: any) => {
            annotation = JSON.parse(JSON.stringify(annotation));
            if (annotation.id.includes('TEMP_')) {
                annotation.id = '00000000-0000-0000-0000-000000000000';
            }
            annotation.pageNo = this.page;
            return annotation;
        };

        const changes: Record<string, boolean> = await this.getChanges();
        const changesExceptStamps = Object.keys(changes)
            .filter(k => k !== 'stamps')
            .map(k => changes[k])
            .filter(c => c).length > 0;

        let document: DocumentModel | undefined = this.currentDocument;

        let hasNewStamp = false;
        const currentStampId = this.annotationQuery.getCurrentStampId();
        let stamp: Stamp | undefined;
        if (currentStampId) {
            stamp = this.annotationQuery.getStampById(currentStampId);
        }
        if (currentStampId && data && changes.stamps) {
            const toast = this.dialogService.showPermanentToast('STAMP.STAMPING_WAITING_MSG', {
                stampName: stamp?.name
            });
            if (this.currentDocument) {
                const newStamp: StampAnnotation = JSON.parse(JSON.stringify(data.stamps))
                    .pop();
                await this.annotationService.addStampToDocument(this.currentDocument.id, {
                    stampId: currentStampId,
                    visualStampArea: {
                        position: {
                            pageNo: this.page,
                            centerX: newStamp.startX,
                            centerY: newStamp.startY
                        },
                        size: {
                            width: newStamp.width,
                            height: newStamp.height
                        }
                    }
                });
            }
            // reset stamps card
            this.annotationService.setStamps([]);

            if (this.currentDocument) {
                document = await this.documentService.waitForDocumentToBeReady(this.currentDocument.id);
            }
            toast.dismiss();

            hasNewStamp = true;
        } else {
            if (document) {
                document = await this.documentService.fetchAndGetDocument(document.id);
            }
        }

        let hasError = false;
        if (changesExceptStamps) {
            const saveData: SinglePageAnnotationData = {
                pageNo: this.page,
                documentVersion: 0,
                notes: (data?.notes || []).map(a => clearAnnotation(a)),
                inks: (data?.inks || []).map(a => clearAnnotation(a)),
                markLines: (data?.markLines || []).map(a => clearAnnotation(a)),
                signatures: (data?.signatures || []).map(a => clearAnnotation(a)),
                highlights: (data?.highlights || []).map(a => clearAnnotation(a))
            };

            saveData.documentVersion = document?.version as number;

            if (document) {
                hasError = await this.documentService.saveAnnotations(document.id, saveData);
                document = await this.documentService.waitForDocumentToBeReady(document.id);
            }
        }

        const canSaveAnnotations = (!hasError && changesExceptStamps) || !changesExceptStamps;
        if (canSaveAnnotations || hasNewStamp) {
            this.documentService.setDocumentViewMode('Viewing');
            this.appService.setShowingSmallMenuContent(false);
            if (!hasNewStamp) {
                this.appService.removeAllCurrentActionMenus();

                // simple wait for annotation changes
                if (document) {
                    await this.documentService.waitForDocumentToBeReady(document.id);
                }
            } else {
                if (document) {
                    await this.annotationService.fetchStampsForDocument(document.id);
                }
                const showStamps = await firstValueFrom(this.annotationQuery.showStamps$.pipe(take(1)));

                //Check if document has stamps - otherwise close the menu
                if (!showStamps) {
                    this.appService.removeAllCurrentActionMenus();
                } else {
                    this.appService.setCurrentActionMenuContent(ACTION_TYPES.STAMPS);
                }
                this.annotationService.actionMenuOnSaveDone.emit();
            }
            if (document) {
                await this.listService.markItem(document.id);
                this.permissionService.removeDocumentPermissionCacheById(document.id);
                await this.permissionService.fetchDocumentPermission(document.id);
                if (this.permissionQuery.hasDocumentPermission(document.id, 'DocumentsGetAnnotations')) {
                    await this.documentService.fetchDocumentAnnotations(document.id, this.page);
                }
            }
            await this.revertChanges();
            this.resetSelectedItemId();
            await this.needToDraw();
        }
        this.appService.hideSpinner();
    }
}
