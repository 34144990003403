import {Component} from '@angular/core';
import {NavigationService} from '../../services/navigation/navigation.service';
import {AppService} from '../../services/app/app.service';
import {AppQuery} from '../../queries/app.query';
import {Observable} from 'rxjs/internal/Observable';
import {SearchQuery as ApiSearchQuery} from '../../api/models/search-query';
import {SearchQuery} from '../../queries/search.query';
import {SearchDocumentQuery} from '../../queries/search-document.query';
import {BrowserQuery} from '../../queries/browser.query';
import {DocumentsSearchResultsService} from '../../services/search/documents-search-results/documents-search-results.service';
import {VaultSearchQueriesService} from '../../services/search/vault-search-queries/vault-search-queries.service';
import {SearchQueriesService} from '../../services/search/search-queries/search-queries.service';
import {Icon} from '../../types/icons/icon.type';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {HistoryItem} from '../../models/history-item';
import {RecentSearchService} from '../../services/search/recent-search/recent-search.service';
import {DocumentService} from '../../services/document/document.service';
import {DebounceCall} from '../../util/decorators/debounce-call.decorator';

@Component({
    selector: 'app-stored-searches',
    templateUrl: './stored-searches.component.html',
    styleUrls: ['./stored-searches.component.scss']
})
export class StoredSearchesComponent extends BasicSubscribableComponent {
    protected storedSearchItems: Array<{ title: string; icon: Icon; link: Array<string> }>;
    protected isGlobalScope$: Observable<boolean>;
    protected storedSearches$: Observable<Array<ApiSearchQuery>>;
    private readonly searchIconPath: string;

    constructor(
        private navigationService: NavigationService,
        private appService: AppService,
        private appQuery: AppQuery,
        private browserQuery: BrowserQuery,
        private searchQuery: SearchQuery,
        private searchDocumentQuery: SearchDocumentQuery,
        private documentsSearchResultsService: DocumentsSearchResultsService,
        private vaultSearchQueriesService: VaultSearchQueriesService,
        private searchQueriesService: SearchQueriesService,
        private documentService: DocumentService,
        private recentSearchService: RecentSearchService,
    ) {
        super();
        this.isGlobalScope$ = this.appQuery.isSearchTaskGlobalScope$;
        this.storedSearches$ = this.searchQuery.storedSearches$;
        this.storedSearchItems = [
            {
                title: 'FAVORITES',
                icon: this.ICONS.FAVORITE,
                link: this.browserQuery.hasSmallViewport() ? ['me', 'favorites'] : ['search', 'favorites'],
            },
            {
                title: 'NEWEST_DOCUMENTS',
                icon: this.ICONS.NEWITEMS,
                link: ['search', 'newest-documents'],
            },
            {
                title: 'LAST_EDITED_DOCUMENTS',
                icon: this.ICONS.LASTEDITED,
                link: ['search', 'last-edited-documents'],
            },
        ];
        this.subscriptions.add(this.isGlobalScope$.subscribe(async isGlobalScope => {
            if (!isGlobalScope) {
                await this.vaultSearchQueriesService.fetch();
            }
        }));
        this.searchIconPath = this.browserQuery.isDarkModeEnabled() ? 'assets/images/find_unselected.png' : 'assets/images/find_selected.png';
    }

    protected async openPage(item: { title: string; icon: string; link: Array<string> }): Promise<void> {
        const searchIcon = this.browserQuery.isDarkModeEnabled() ? this.ICONS.FIND_UNSELECTED : this.ICONS.FIND_SELECTED;
        const historyItem: HistoryItem = { title: 'STORED_SEARCHES', subTitle: item.title, icon: searchIcon };
        await this.navigationService.navigate(item.link, undefined, false, historyItem);
        this.appService.hideSearchMenu();
    }

    // This prevents the search from being performed multiple times when performing multiple clicks on the searchItem
    @DebounceCall(300)
    protected async openStoredSearch(item: ApiSearchQuery): Promise<void> {
        await this.searchQueriesService.fetch(item.id);
        const searchInfo = this.searchDocumentQuery.getSearchInformation();

        if (!searchInfo) {
            return;
        }

        let hasNavigated: boolean;
        const searchResult = await this.documentsSearchResultsService.fetch(searchInfo.searchId as string, 0, 1, true, true);
        if (searchResult.data.length > 0) {
            if (this.browserQuery.hasSmallViewport()) {
                if (searchInfo.totalCount > 1) {
                    hasNavigated = await this.navigate(['search', 'stored', item.id], item);
                } else {
                    hasNavigated = await this.navigate(['search', 'result', searchInfo.searchId, 'vault', item.vaultId, 'document', searchResult.data[0].id], item);
                }

            } else {
                hasNavigated = await this.navigate(['search', 'stored', item.id, 'document', searchResult.data[0].id], item);
            }
        } else {
            await this.recentSearchService.add({
                keyword: item.name,
                totalCount: 0,
                vaultIds: [item.vaultId],
                vaultSearchInformation: []
            });

            hasNavigated = await this.navigate(['search', 'no-result'], item);
        }

        if (hasNavigated) {
            this.documentService.unsetSelectedDocuments();
            this.appService.hideSearchMenu();
        }
    }

    private async navigate(url: Array<string>, item: ApiSearchQuery): Promise<boolean> {
        return await this.navigationService.navigate(url, undefined, false, {
            title: 'STORED_SEARCHES',
            subTitle: item.name,
            icon: this.searchIconPath
        });
    }
}
