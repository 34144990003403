import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AppService} from '../../services/app/app.service';
import {ServerAddress} from 'src/app/models/server-address';
import {AppQuery} from '../../queries/app.query';
import {ServerSelectionService} from '../../services/server-selection/server-selection.service';
import {take} from 'rxjs/operators';
import {secureProtocol} from '../../constants/server/secure-protocol.constant';
import {IconsComponent} from '../dummy-components/icons.component';
import {DialogService} from '../../services/dialog/dialog.service';

@Component({
    selector: 'app-server-selection',
    templateUrl: './server-selection.component.html',
    styleUrls: ['./server-selection.component.css']
})
export class ServerSelectionComponent extends IconsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('serverAddressInput') serverAddressInput: ElementRef | undefined;
    @Output() done: EventEmitter<boolean>;
    protected form: FormGroup;
    protected selectedServer$: BehaviorSubject<ServerAddress | undefined>;
    protected hasServerAddresses$: Observable<boolean>;
    protected listItems: Array<ServerAddress>;
    protected isLoading: boolean;
    protected canSelectServer!: boolean;
    private previousSelectedServerHost: string | undefined;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private appService: AppService,
        private appQuery: AppQuery,
        private serverSelectionService: ServerSelectionService,
        private dialogService: DialogService
    ) {
        super();
        this.listItems = [];
        this.done = new EventEmitter<boolean>();
        this.isLoading = true;
        this.selectedServer$ = this.serverSelectionService.selectedServer$;
        this.hasServerAddresses$ = this.serverSelectionService.hasServerAddresses$;
        this.form = this.formBuilder.group({
            serverAddress: new FormControl<string | null>(''),
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public async ngOnInit(): Promise<void> {
        this.previousSelectedServerHost = this.appQuery.getSelectedServer()?.url;
        this.serverSelectionService.loadServers();

        const hasServerAddresses = await firstValueFrom(this.hasServerAddresses$.pipe(take(1)));
        if (!hasServerAddresses) {
            this.serverSelectionService.addDefaultServerAddress();
            this.serverSelectionService.loadServers();
        }

        this.subscriptions.add(this.selectedServer$.subscribe(selectedServer => {
            const serverAddresses = this.serverSelectionService.serverAddresses$.getValue();
            const serverAddressesWithSelectedServer = serverAddresses.map(serverAddress => {
                serverAddress.selected = (selectedServer?.displayUrl === serverAddress.displayUrl);

                return serverAddress;
            });

            this.serverSelectionService.serverAddresses$.next(serverAddressesWithSelectedServer);

            if(serverAddresses.length === 0) {
                this.canSelectServer = false;
            }
        }));

        this.subscriptions.add(this.serverSelectionService.serverAddresses$
            .subscribe(async (serverAddresses) => {
                const sortedServerAddresses = serverAddresses.sort(this.sortServerAddresses);
                const updatedServerAddresses: ServerAddress[] = [];

                for (const serverAddress of sortedServerAddresses) {
                    const updatedServerAddress = await this.serverSelectionService.loadServerInformation(serverAddress);

                    if (updatedServerAddress.selected) {
                        this.canSelectServer =  !updatedServerAddress.isOutdated && !updatedServerAddress.isOffline && !updatedServerAddress.serverInformation.maintenanceMessage;
                    }

                    updatedServerAddresses.push(updatedServerAddress);
                }

                this.listItems = updatedServerAddresses;
                this.isLoading = false;
            }));
    }

    public ngAfterViewInit(): void {
        window.setTimeout(() => {
            this.focusInput();
        }, 500);
    }

    protected focusInput(): void {
        if (this.serverAddressInput instanceof ElementRef) {
            this.serverAddressInput.nativeElement.focus();
        }
    }

    protected blurInput(): void {
        if (this.serverAddressInput instanceof ElementRef) {
            this.serverAddressInput.nativeElement.blur();
        }
    }

    protected close(): void {
        this.appService.selectServer(this.previousSelectedServerHost);
        this.done.emit(false);
    }

    protected selectServer(hostName: string, deselect: boolean = true): void {
        this.serverSelectionService.selectServer(hostName, deselect);
    }

    protected addServer(): void {
        if (this.form.valid) {
            const formData = this.form.getRawValue();
            if (formData.serverAddress && formData.serverAddress.length > 4) {
                this.appService.showSpinner();
                const serverUrl = this.serverSelectionService.cleanUrl(formData.serverAddress, false);
                const hasServerHost = this.serverSelectionService.hasServerHost(serverUrl);

                if (!hasServerHost) {
                    const serverInformation = {
                        serverVersion: '',
                        maintenanceMessage: '',
                        notification: null,
                        registrationLinkUrl: '',
                        systemMessage: '',
                        apiVersion: ''
                    };
                    const newServerAddress = {
                        loginCount: 0,
                        displayUrl: serverUrl,
                        url: serverUrl,
                        name: serverUrl,
                        selected: false,
                        remember: true,
                        successfulLoggedIn: false,
                        serverInformation,
                        isOutdated: false,
                        isOffline: false,
                        currentNotificationId: undefined,
                        notificationReadByUserIds: [],
                    };
                    this.serverSelectionService.addServer(newServerAddress)
                        .then(success => {
                            if (success) {
                                this.form.reset();
                                this.blurInput();
                            }
                            this.appService.hideSpinner();
                        });
                } else {
                    this.serverSelectionService.selectServer(serverUrl, false);
                    this.form.reset();
                    this.blurInput();
                    this.appService.hideSpinner();
                }
            }
        }
    }

    protected async reloadServerInformation(serverAddress: ServerAddress): Promise<void> {
        this.isLoading = true;

        const serverAddresses = this.serverSelectionService.serverAddresses$.getValue();
        const updatedServerAddress = await this.serverSelectionService.loadServerInformation(serverAddress);

        if (updatedServerAddress.selected) {
            this.selectedServer$.next(updatedServerAddress);
        }

        this.serverSelectionService.serverAddresses$.next(serverAddresses.map(serverAddress => {
            return serverAddress.displayUrl === updatedServerAddress.displayUrl ? updatedServerAddress : serverAddress;
        }));

        this.serverSelectionService.saveServers();
        this.isLoading = false;
    }

    protected async showMaintenanceMessage(maintenanceMessage: string): Promise<void> {
        await this.dialogService.showConfirmDialog({
            message: maintenanceMessage,
            title: 'SERVER_SELECTION.MAINTENANCE_MESSAGE.TITLE',
            cancelText: undefined,
            confirmText: 'BUTTON.OK',
            appTestTag: 'read-maintenance-message-dialog'
        });
    }

    protected removeSelectedServer(): void {
        const selectedServer = this.serverSelectionService.selectedServer$.getValue();
        if (selectedServer) {
            this.serverSelectionService.removeServer(selectedServer.name, selectedServer.displayUrl);
        }
    }

    protected saveServerSelection(): void {
        const hasChanges = this.serverSelectionService.hasChanges();
        if (hasChanges) {
            this.serverSelectionService.saveServers();
        }
        this.done.emit(hasChanges);
    }

    private sortServerAddresses(firstServerAddress: ServerAddress, secondServerAddress: ServerAddress): number {
        return (firstServerAddress.name || secureProtocol + firstServerAddress.url)
            .localeCompare((secondServerAddress.name || secureProtocol + secondServerAddress.url));
    }
}
