<h1>{{ 'SERVER_SELECTION.CONNECTIONS' | translate }}</h1>
<div class="scrollable">
    <form (ngSubmit)="addServer()" [formGroup]="form">
        <h2>{{ 'SERVER_SELECTION.ADD' | translate }}</h2>
        <mat-form-field class="full-width-input no-top-padding login-form-field white-form-field server-input-field">
            <input #serverAddressInput [placeholder]="'SERVER_SELECTION.PLACEHOLDER' | translate" data-qa="server-selection-server-input"
                   formControlName="serverAddress" matInput name="host" type="text"/>
            <button [disabled]="!form.valid || form.controls.serverAddress.value === '' || form.controls.serverAddress.value === null" color="accent" mat-icon-button matSuffix type="submit">
                <mat-icon [svgIcon]="ICONS.ADD_NEW"></mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="server-list">
        <h2>{{ 'SERVER_SELECTION.SELECT' | translate }}</h2>
        <app-static-list
                #appStaticList
                [allowedListDisplayTypes]="['list']"
                [listId]="'SERVER_SELECTION.LIST'"
                [noElementsMsg]="'SERVER_SELECTION.NO_SERVER'"
                testTag="server-select-list-scroll-viewport">
            @if (isLoading) {
                <app-loading-spinner [show]="isLoading"></app-loading-spinner>
            }
            @if (listItems && !isLoading) {
                @for (item of listItems; track item.name) {
                    <div [ngClass]="{'is-selected': item.selected }"
                         [ngStyle]="{width: appStaticList.itemWidth, minHeight: appStaticList.itemHeight}" class="item-wrapper">
                        <button class="button select-server-button"
                                [ngClass]="{'is-selected': item.selected }"
                                (click)="selectServer(item.displayUrl)"
                                (dblclick)="selectServer(item.displayUrl, false); saveServerSelection();"
                                [attr.data-qa]="'select-server-' + item.displayUrl">
                            @if (item.isOffline || item.isOutdated || item.serverInformation.maintenanceMessage) {
                                <app-icon [svgIcon]="ICONS.WARNING_ORANGE" class="server-status-icon warning" [attr.data-qa]="'warn-icon-select-server-' + item.displayUrl"></app-icon>
                            } @else {
                                <app-icon [svgIcon]="ICONS.SERVER" class="server-status-icon default-server-icon" [attr.data-qa]="'icon-select-server-' + item.displayUrl"></app-icon>
                            }
                            <div class="server-information">
                                <div class="server-name" [attr.data-qa]="'server-name-select-server-' + item.displayUrl">{{ item.name }}</div>
                                <div class="server-url" [attr.data-qa]="'server-url-select-server-' + item.displayUrl">{{ item.displayUrl }}</div>
                                @if (item.serverInformation.maintenanceMessage) {
                                    <div class="server-warning" [attr.data-qa]="'maintenance-message-select-server-' + item.displayUrl">{{ 'SERVER_SELECTION.MAINTENANCE' | translate }}</div>
                                } @else if (item.isOutdated) {
                                    <div class="server-warning" [attr.data-qa]="'server-outdated-select-server-' + item.displayUrl">{{ 'SERVER_SELECTION.SERVER_OUTDATED' | translate }}</div>
                                } @else if (item.isOffline) {
                                    <div class="server-warning" [attr.data-qa]="'server-offline-select-server-' + item.displayUrl">{{ 'SERVER_SELECTION.SERVER_UNAVAILABLE' | translate }}</div>
                                }
                            </div>
                        </button>

                        @if (item.isOffline || item.isOutdated || item.serverInformation.maintenanceMessage) {
                            <div class="server-status">
                                @if (item.serverInformation.maintenanceMessage) {
                                    <button class="button read-maintenance-message-button" [attr.data-qa]="'show-maintenance-message-select-server-' + item.displayUrl"
                                            (click)="showMaintenanceMessage(item.serverInformation.maintenanceMessage)">
                                        {{ 'SERVER_SELECTION.READ_MAINTENANCE_MESSAGE' | translate }}
                                    </button>
                                }

                                <button class="button check-connection-button" [attr.data-qa]="'check-connection-select-server-' + item.displayUrl" (click)="reloadServerInformation(item)">
                                    {{ 'SERVER_SELECTION.CHECK_CONNECTION' | translate }}
                                </button>
                            </div>
                        }
                    </div>
                }
            }
        </app-static-list>
    </div>
</div>
<div class="actions">
    @if (selectedServer$ | async) {
        <button (click)="removeSelectedServer()" class="delete-btn bg-white no-box-shadow"
                data-qa="delete-server-selection-button"
                mat-icon-button
                type="button">
            <mat-icon [svgIcon]="ICONS.TRASH"></mat-icon>
        </button>
    }
    <span class="right-buttons">
        @if (canSelectServer) {
            <button (click)="saveServerSelection()" class="save-btn bg-white no-box-shadow"
                    data-qa="save-server-selection-button"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.OK" class="color-green"></mat-icon>
            </button>
        }
        @if (hasServerAddresses$ | async) {
            <button (click)="close()" class="close-btn bg-white no-box-shadow" data-qa="close-server-selection-button"
                    mat-mini-fab
                    type="button">
                <mat-icon [svgIcon]="ICONS.CLOSE"></mat-icon>
            </button>
        }
    </span>
</div>
