import {Injectable} from '@angular/core';
import {AuthQuery} from '../../../queries/auth.query';
import {
    IPC_APP_BEFORE_QUIT,
    IPC_APP_READY_SIGNAL,
    IPC_AUTH_TOKEN_CHANGED,
    IPC_DOWNLOAD_AND_INSTALL_UPDATE,
    IPC_GLOBAL_DOCUMENT_DOWNLOAD_DONE,
    IPC_GLOBAL_DOCUMENT_DOWNLOAD_ERROR,
    IPC_GLOBAL_DOCUMENT_DOWNLOAD_STARTED,
    IPC_GLOBAL_SHORTCUT,
    IPC_IMPORT_ERROR,
    IPC_IMPORT_FILE,
    IPC_OPEN_DEEP_LINK,
    IPC_OPEN_FILE,
    IPC_UPDATE_AVAILABLE
} from '../../../constants/ipc-message-constants';
import {AppService} from '../../app/app.service';
import {DialogService} from '../../dialog/dialog.service';
import {ImportService} from '../../import/import.service';
import {AuthService} from '../../auth/auth.service';
import {DeepLinkService} from '../../deep-link/deep-link.service';
import {environment} from '../../../../environments/environment';
import {UploadFile} from '../../../models/upload-file';
import {ElectronService} from '../electron.service';
import {TranslationKey} from '../../../types/available-translations';
import {SearchService} from '../../search/search.service';

@Injectable({
    providedIn: 'root',
})
export class ElectronAppService {
    private lastRequestedDocumentName: string;

    constructor(
        private appService: AppService,
        private authQuery: AuthQuery,
        private authService: AuthService,
        private dialogService: DialogService,
        private deepLinkService: DeepLinkService,
        private importService: ImportService,
        private searchService: SearchService,
    ) {
        this.lastRequestedDocumentName = '';
    }

    public setLastRequestedDocumentName(name: string): void {
        this.lastRequestedDocumentName = name;
    }

    public registerDeepLinkListener(): void {
        ElectronService.ipcRender?.receive(
            IPC_OPEN_DEEP_LINK,
            this.appService.handleExternalEvent((url: string) => {
                this.authService.continueAfterLoggedIn(async (): Promise<boolean> => {
                    await this.globalShortcutCalled(url);
                    return true;
                });
            })
        );
    }

    public registerOpenFileListener(): void {
        ElectronService.ipcRender?.receive(
            IPC_OPEN_FILE,
            this.appService.handleExternalEvent(() => {
                this.importFiles();
            })
        );
    }

    public registerUpdateNotifier(): void {
        ElectronService.ipcRender?.receive(
            IPC_UPDATE_AVAILABLE,
            this.appService.handleExternalEvent((version: string) => {
                if (environment.version.replace('-dev', '') === version.replace('-dev', '')) {
                    return;
                }
                const updateOverlay = this.dialogService.showUpdateAvailableToast(version, () => {
                    this.appService.showSpinner();
                    ElectronService.ipcRender?.invoke(IPC_DOWNLOAD_AND_INSTALL_UPDATE)
                        .then(updated => {
                            this.appService.hideSpinner();
                            updateOverlay.dispose();
                            if (!updated) {
                                let link = environment.updateUrlMac;
                                if (ElectronService.isWindows()) {
                                    link = environment.updateUrlWindows;
                                }
                                this.dialogService.showConfirmDialog({
                                        messageKey: 'UPDATE_ERROR_MESSAGE',
                                        title: 'UPDATE_ERROR_TITLE',
                                        confirmText: 'UPDATE_ERROR_CONFIRM_TEXT',
                                        cancelText: 'BUTTON.CANCEL',
                                        appTestTag: 'update-error',
                                    })
                                    .then(async confirmed => {
                                        if (confirmed) {
                                            await ElectronService.openExternal(link);
                                        }
                                    });
                            }
                        });
                });
            })
        );
    }

    public registerFileImportListener(): void {
        ElectronService.ipcRender?.receive(
            IPC_IMPORT_FILE,
            this.appService.handleExternalEvent((fileArrays: Array<[string, string, Blob, number]>) => {
                this.authService.continueAfterLoggedIn(async (): Promise<boolean> => {
                    if (this.importService.importDialogOpen() && this.importService.hasFiles()) {
                        this.dialogService.showError('IMPORT.ALREADY_STARTED_CONFIRM');
                        return false;
                    }

                    const files = [];
                    for (const fileArray of fileArrays) {
                        const [filePath, filename, fileData, birthtimeInMS] = fileArray;
                        const file = new File([fileData], filename) as UploadFile;
                        file.filePath = filePath;
                        file.creationDate = Math.floor(birthtimeInMS);
                        files.push(file);
                    }
                    this.importService.showImportDialog(files);
                    return true;
                });
            })
        );

        ElectronService.ipcRender?.receive(
            IPC_IMPORT_ERROR,
            this.appService.handleExternalEvent(([errorMsgKey, error, filename]: [TranslationKey, Error | undefined, string | undefined]) => {
                console.log(IPC_IMPORT_ERROR, errorMsgKey, error);
                if (error) {
                    this.dialogService.showError(errorMsgKey, error as Error, { filename });
                } else {
                    this.dialogService.showInfo(errorMsgKey);
                }
            })
        );
    }

    public sendReadySignal(): void {
        ElectronService.ipcRender?.send(IPC_APP_READY_SIGNAL);
    }

    public async tryInitElectronApp(): Promise<boolean> {
        if (!ElectronService.isElectronApp()) {
            return false;
        }

        this.authQuery.token$.subscribe(token => {
            ElectronService.ipcRender?.send(IPC_AUTH_TOKEN_CHANGED, token);
        });

        ElectronService.ipcRender?.receive(
            IPC_GLOBAL_SHORTCUT,
            this.appService.handleExternalEvent((a: string) => this.globalShortcutCalled(a))
        );

        ElectronService.ipcRender?.receive(
            IPC_GLOBAL_DOCUMENT_DOWNLOAD_STARTED,
            this.appService.handleExternalEvent((a: string) => {
                this.appService.showSpinner();
            })
        );

        ElectronService.ipcRender?.receive(
            IPC_GLOBAL_DOCUMENT_DOWNLOAD_DONE,
            this.appService.handleExternalEvent((a: string) => {
                this.appService.hideSpinner();
                if (this.lastRequestedDocumentName) {
                    this.dialogService.showSuccess('EXPORT_DOCUMENT_SUCCESS', { document: this.lastRequestedDocumentName });
                    this.lastRequestedDocumentName = '';
                }
            })
        );

        ElectronService.ipcRender?.receive(
            IPC_GLOBAL_DOCUMENT_DOWNLOAD_ERROR,
            this.appService.handleExternalEvent(() => {
                this.appService.hideSpinner();
                if (this.lastRequestedDocumentName) {
                    this.dialogService.showError('FILE_DOWNLOAD_ERROR_MSG');
                    this.lastRequestedDocumentName = '';
                }
            })
        );

        return true;
    }

    public beforeAppQuit(func: () => unknown): void {
        ElectronService.ipcRender?.receive(IPC_APP_BEFORE_QUIT, func);
    }

    private importFiles(): void {
        this.authService.continueAfterLoggedIn(async (): Promise<boolean> => {
            if (this.importService.importDialogOpen() && this.importService.hasFiles()) {
                this.dialogService.showError('IMPORT.ALREADY_STARTED_CONFIRM');
                return false;
            }

            let error: Error | undefined;
            //TODO: ELECTRON put this into electron itself or use capacitor
            const hasFolderError = true;
            const files: Array<UploadFile> = [];

            if (hasFolderError) {
                this.dialogService.showInfo('IMPORT.ERROR_FOLDERS_CURRENTLY_NOT_SUPPORTED');
                return false;
            }

            if (error) {
                this.dialogService.showError('ERROR_READING_FILE', error as Error);
                return false;
            }

            this.importService.showImportDialog(files);
            return true;
        });
    }

    private async globalShortcutCalled(clipboardText: string): Promise<void> {
        if (clipboardText.startsWith(environment.deepLinkPrefix) || clipboardText.startsWith('url:' + environment.deepLinkPrefix)) {
            await this.deepLinkService.openAmagnoLink(clipboardText);
        } else {
            await this.searchService.executeSearchAndOpenResults(clipboardText, undefined, false, true);
        }
    }
}
