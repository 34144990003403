import {Injectable} from '@angular/core';
import {SearchDocumentStore} from '../../stores/search-documents.store';
import {DocumentsTextItemsService} from './documents-text-items/documents-text-items.service';
import {DocumentsInitGlobalSearchService} from './documents-init-global-search/documents-init-global-search.service';
import {SearchResultViewService} from './search-result-view/search-result-view.service';
import {RecentSearchService} from './recent-search/recent-search.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(
        private searchDocumentStore: SearchDocumentStore,
        private documentsTextItemsService: DocumentsTextItemsService,
        private documentsInitGlobalSearchService: DocumentsInitGlobalSearchService,
        private searchResultViewService: SearchResultViewService,
        private recentSearchService: RecentSearchService,
    ) {
        this.documentsTextItemsService.setSelectedTextItems(undefined);
        this.searchDocumentStore.update({ searchQueryId: undefined });
    }

    public async executeSearchAndOpenResults(searchTerm: string, vaultIdCollection?: string[], useCachedResult?: boolean, isGlobalView?: boolean): Promise<boolean> {
        let hasOpenedSearchResults;

        const searchResult = await this.documentsInitGlobalSearchService.fetch(searchTerm, vaultIdCollection, useCachedResult);
        if (searchResult) {
            hasOpenedSearchResults = await this.searchResultViewService.openResultView(searchResult, useCachedResult, isGlobalView);
            await this.recentSearchService.add(searchResult);
        } else {
            hasOpenedSearchResults = await this.searchResultViewService.openNoResultView(false, false);
        }

        return hasOpenedSearchResults;
    }
}
