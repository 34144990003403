@if (isGlobalScope$ | async) {
    @if (storedSearchItems.length > 0) {
        <h3>{{ 'STORED_SEARCHES'|translate }}</h3>
    }
    <mat-list>
        @for (storedSearchItem of storedSearchItems; track i; let i = $index) {
            <mat-list-item (click)="openPage(storedSearchItem)" [attr.data-qa]="'stored-search-'+storedSearchItem.title" class="cursor-pointer">
                <mat-icon [svgIcon]="storedSearchItem.icon" class="has-custom-icon" matListItemIcon></mat-icon>
                <div matListItemTitle>
                    <span class="last-name">{{ storedSearchItem.title|translate|titlecase }}</span>
                </div>
            </mat-list-item>
        }
    </mat-list>
} @else {
    @if (storedSearches$ | async; as storedSearches) {
        @if (storedSearches.length > 0) {
            <h3 data-qa="stored-search-title">{{ 'STORED_SEARCHES' | translate }}</h3>
        }
        <mat-list>
            @for (storedSearch of storedSearches; track i; let i = $index) {
                <mat-list-item (click)="openStoredSearch(storedSearch)" [attr.data-qa]="'stored-search-entry-' + storedSearch.id" class="cursor-pointer">
                    <mat-icon [svgIcon]="storedSearch.visibility === 'Personal' ? ICONS.STOREDSEARCH_PERSONAL : ICONS.STOREDSEARCH_PUBLIC" class="has-custom-icon" matListItemIcon></mat-icon>
                    <div matListItemTitle>
                        <span class="last-name">{{ storedSearch.name }}</span>
                    </div>
                </mat-list-item>
            }
        </mat-list>
    }
}
