import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {AppQuery} from '../../queries/app.query';
import {AppService} from '../../services/app/app.service';
import {VaultQuery} from '../../queries/vault.query';
import {Vault} from '../../api/models/vault';
import {SearchQuery} from '../../queries/search.query';
import {ImprovedGlobalSearchInformation} from '../../models/improved-global-search-information';
import {DeepLinkService} from '../../services/deep-link/deep-link.service';
import {environment} from '../../../environments/environment';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {CacheService} from '../../services/cache/cache.service';
import {CACHE_GROUP_IDS} from '../../constants/cache/cache-group-id.constants';
import {SearchService} from '../../services/search/search.service';

@Component({
    selector: 'app-search-dashboard',
    templateUrl: './search-dashboard.component.html',
    styleUrls: ['./search-dashboard.component.scss']
})
export class SearchDashboardComponent extends BasicSubscribableComponent implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchInput: ElementRef | undefined;
    @Input() isFocusInputDisabled: boolean;

    protected isGlobalSearchView$: Observable<boolean>;
    protected form: FormGroup;
    protected recentGlobalSearches$: Observable<Array<ImprovedGlobalSearchInformation>>;
    private isGlobalView: boolean;
    private activeVault: Vault | undefined;

    constructor(
        private searchQuery: SearchQuery,
        private appQuery: AppQuery,
        private appService: AppService,
        private formBuilder: FormBuilder,
        private deepLinkService: DeepLinkService,
        private vaultQuery: VaultQuery,
        private cacheService: CacheService,
        private searchService: SearchService,
    ) {
        super();
        this.isFocusInputDisabled = false;
        this.form = this.formBuilder.group({
            keyword: new FormControl<string | null>(''),
        });
        this.isGlobalView = true;
        this.recentGlobalSearches$ = this.searchQuery.recentGlobalSearches$;
        this.isGlobalSearchView$ = appQuery.isSearchTaskGlobalScope$;
        this.subscriptions.add(this.isGlobalSearchView$.subscribe(isGlobal => {
            this.isGlobalView = isGlobal;
        }));
        this.subscriptions.add(this.vaultQuery.activeVault$.subscribe(activeVault => {
            this.activeVault = activeVault;
        }));
    }

    public ngAfterViewInit(): void {
        if (!this.isFocusInputDisabled) {
            this.focusInput();
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.blurInput();
    }

    protected getVaultNamesByVaultIds(vaultIds: Array<string>): string {
        return vaultIds.map(v => {
                return this.vaultQuery.getVaultNameById(v);
            })
            .join(', ');
    }

    protected async directSearch(keyword: string): Promise<void> {
        this.cacheService.deleteCacheItemsByGroupById(CACHE_GROUP_IDS.DOCUMENTS_SEARCH);
        this.cacheService.deleteCacheItemsByGroupById(CACHE_GROUP_IDS.DOCUMENTS_INIT_SEARCH);
        await this.executeSearch(keyword, false);
    }

    protected async onSubmit(): Promise<void> {
        this.appService.showSpinner();
        const formValue = this.form.value;
        const keyword = formValue.keyword as string;
        this.clearInput();
        this.blurInput();
        const keywordWithoutUrl = keyword.replace(new RegExp('url:' + environment.deepLinkPrefix), environment.deepLinkPrefix);
        await this.executeSearch(keywordWithoutUrl, false);
        this.appService.hideSpinner();
    }

    protected resetInput(): void {
        this.clearInput();
        this.focusInput();
    }

    protected fillInputWithRecentSearch(keyword: string | undefined): void {
        this.form.patchValue({ keyword });
        this.focusInput();
    }

    private async amagnoSearch(keyword: string, useCachedResult: boolean = false): Promise<boolean> {
        const vaultIds: Array<string> = [];

        if (!this.isGlobalView && this.activeVault && this.activeVault.id) {
            vaultIds.push(this.activeVault.id);
        }

        return await this.searchService.executeSearchAndOpenResults(keyword, vaultIds, useCachedResult, this.isGlobalView);
    }

    private clearInput(): void {
        this.form.patchValue({ keyword: '' });
    }

    private focusInput(): void {
        window.setTimeout(() => {
            if (this.searchInput instanceof ElementRef) {
                this.searchInput.nativeElement.focus();
            }
        }, 1);
    }

    private blurInput(): void {
        window.setTimeout(() => {
            if (this.searchInput instanceof ElementRef) {
                this.searchInput.nativeElement.blur();
            }
        }, 1);
    }

    private async executeSearch(keyword: string, useCachedResult: boolean = false): Promise<boolean> {
        if (keyword && (keyword.startsWith(environment.deepLinkPrefix) || keyword.startsWith('url:' + environment.deepLinkPrefix))) {
            if (await this.deepLinkService.openAmagnoLink(keyword)) {
                this.appService.hideSearchMenu();
                return true;
            }

            return false;
        } else {
            return await this.amagnoSearch(keyword, useCachedResult);
        }
    }
}
