// eslint-disable-next-line @typescript-eslint/naming-convention
import 'reflect-metadata';
import {environment} from '../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DebounceCall<T>(debounceTime: number = 300) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const sourceMethod = descriptor.value;

        // eslint-disable-next-line
        descriptor.value = async function (...args: Array<unknown>): Promise<T> {
            if (!(propertyKey in DebounceCall.pending) || DebounceCall.pending[propertyKey] === undefined) {
                return DebounceCall.pending[propertyKey] = new Promise<T>(async resolve => {
                    if (propertyKey in DebounceCall.timeout) {
                        window.clearTimeout(DebounceCall.timeout[propertyKey]);
                    }

                    DebounceCall.timeout[propertyKey] = window.setTimeout(async () => {
                        const returnValue: any = await sourceMethod.apply(this, args);
                        delete DebounceCall.timeout[propertyKey];
                        delete DebounceCall.pending[propertyKey];
                        resolve(returnValue);
                    }, debounceTime);
                });
            } else {
                if (!environment.production && !location.href.includes('test')) {
                    (console as Console).info('Prevented', propertyKey, 'getting called multiple times in ' + debounceTime + ' ms.');
                }
                return DebounceCall.pending[propertyKey] as Promise<T>;
            }
        };
        return descriptor;
    };
}

DebounceCall.pending = {} as { [method: string]: Promise<unknown> | undefined };
DebounceCall.timeout = {} as { [method: string]: number };
